import React, { useState, useEffect } from 'react';
import xhr from '../xhr';
import config from '../config';

const DeploymentProgress: React.FC<any> = ({ onClose, releaseId }) => {
  const [logContent, setLogContent] = useState('');

  useEffect(() => {
    if (!releaseId) return; // Don't fetch logs if releaseId is null

    const fetchLogs = () => {
      xhr.get(`${config.API_BASE_URL}/api/releases/log/${releaseId}`)
        .then((response) => response.data)
        .then((newContent: any) => {
          setLogContent(newContent); // Replace the content instead of appending
        })
        .catch((error) => {
          console.error('Error fetching logs:', error);
        });
    };

    fetchLogs();
    const interval = setInterval(fetchLogs, 1000);

    return () => clearInterval(interval);
  }, [releaseId]);

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    } else {
      console.warn('onClose prop is not a function');
    }
  };

  return (
    <div className="deployment-progress">
      <textarea
        value={logContent}
        readOnly
        style={{ width: '600px', height: '600px', marginBottom: '20px' }}
      />
      <button onClick={handleClose}>Close</button>
    </div>
  );
};

export default DeploymentProgress;