import React from 'react';
import { useModal } from './ModalContext';
import { Button, styled } from '@mui/material';

const ModalOverlay = styled(`div`)({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

const ModalContext = styled(`div`)({
    backgroundColor: '#fefefe',
    padding: '20px',
    border: '1px solid #888',
    width: '604px',
    borderRadius: '5px',
    textAlign: 'left',
})

const ModalButtonGroup = styled(`div`)({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 6rem)',
    justifyContent: 'end',
    gridGap: '1rem',
});

const Modal = () => {
    const { isOpen, modalTitle, modalContent, closeModal, onConfirm, confirmText } = useModal();

    if (!isOpen) return null;

    return (
        <ModalOverlay>
            <ModalContext>
                <h2>{modalTitle}</h2>
                <div>{modalContent}</div>
                <ModalButtonGroup>
                    <Button variant="contained" onClick={() => closeModal()}>Close</Button>
                    {onConfirm && (
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (onConfirm) {
                                    onConfirm();
                                }
                            closeModal();
                        }}>
                            {confirmText}
                        </Button>
                    )}
                </ModalButtonGroup>
            </ModalContext>
        </ModalOverlay>
    );
};

export default Modal;