import React, { ChangeEvent, useEffect, useState } from 'react';
import { Snackbar, Alert, Chip, Button, Pagination, Skeleton, styled } from '@mui/material';
import xhr from '../xhr';
import CommitRow from './CommitRow';
import config from '../config';
import LandingComponent from './Landing';
import { useModal } from './Modal/ModalContext';

type Service = {
  service_name: string;
  is_continuous_deployment: boolean;
  facilities?: string[];
  k8sSelectorLabel: string;
};

type PodsInfo = {
  facility: string;
  labelSelector: string;
  totalPods: number;
  podsDetails: Array<{
    name: string;
    age: string;
    status: string;
    containers: Array<{
      image: string;
      name: string;
    }>;
  }>;
};

type Pod = {
  name: string;
  age: string;
  status: string;
  containers: { image: string; name: string; }[];
}

type ServiceDetailsProps = {
  serviceId: string;
};

const ServiceDetails: React.FC<ServiceDetailsProps> = ({ serviceId }) => {
  const rows = Array.from({ length: 10 });
  const [service, setService] = useState<Service | null>(null);
  const [images, setImages] = useState<any[]>([]); // Update with a proper type if available
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [podsInfo, setPodsInfo] = useState<PodsInfo[]>([]);
  const [deployedMap, setDeployedMap] = useState<Record<string, string[]>>({});
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | undefined | string | number>(undefined);
  const [currentService, setCurrentService] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const { openModal } = useModal();

  useEffect(() => {
    setPodsInfo([]);
    setImages([]);
    if (serviceId) {
      fetchService(serviceId);
    }

    return () => {
      clearInterval(pollingInterval);
    };
  }, [serviceId]);

  useEffect(() => {
    if (service) {
      startPolling(service);
    }
  }, [service, currentService]);

  const fetchService = (id: string) => {
    xhr.get(`${config.API_BASE_URL}/api/services/${id}`)
        .then(response => {
          setService(response.data);
          fetchImages(id, 1, true);
        })
        .catch(error => console.error('Error fetching service:', error));
  };

  const fetchImages = (serviceId: string, page: number, reset: boolean = false) => {
    setImages([]);
    xhr.get(`${config.API_BASE_URL}/api/images?service_id=${serviceId}&page=${page}&limit=10`)
        .then(response => {
          const newImages = response.data;
          setImages(reset ? newImages : [...images, ...newImages]);
          setPage(page);
        })
        .catch(error => console.error('Error fetching images:', error));
  };

  const fetchPodsInfo = (service: Service) => {
    if (currentService !== service.k8sSelectorLabel) {
      setPodsInfo([]);
      setDeployedMap({});
      setCurrentService(service.k8sSelectorLabel);
    }

    if (service.facilities && service.k8sSelectorLabel) {
      const facilities = service.facilities;
      facilities.forEach(facility => {
        xhr.get(`${config.API_BASE_URL}/api/k8s/pods`, {
          params: {
            facility,
            namespace: 'fulfil-prod',
            labelSelector: service.k8sSelectorLabel,
          },
        })
            .then(response => {
              if (currentService === service.k8sSelectorLabel) {
                setPodsInfo(prevPodsInfo => {
                  const existingFacilityIndex = prevPodsInfo.findIndex(
                      podInfo => podInfo.facility === facility && podInfo.labelSelector === service.k8sSelectorLabel
                  );
                  let newPodsInfo = [...prevPodsInfo];
                  const newPodInfo: PodsInfo = {
                    facility,
                    labelSelector: service.k8sSelectorLabel,
                    totalPods: response.data.totalPods,
                    podsDetails: response.data.podsDetails,
                  };

                  if (existingFacilityIndex >= 0) {
                    newPodsInfo[existingFacilityIndex] = newPodInfo;
                  } else {
                    newPodsInfo.push(newPodInfo);
                  }
                  return newPodsInfo.filter(a => a.labelSelector === service.k8sSelectorLabel)
                      .sort((a, b) => a.facility.localeCompare(b.facility));
                });

                response.data.podsDetails.forEach((pod: Pod) => {
                  pod.containers.forEach((container: {image: string, name: string;}) => {
                    const version = extractVersion(container.image);
                    setDeployedMap(prevMap => {
                      const newMap = { ...prevMap };
                      if (!newMap[version]) {
                        newMap[version] = [];
                      }
                      if (!newMap[version].includes(facility)) {
                        newMap[version].push(facility);
                      }
                      return newMap;
                    });
                  });
                });
              }
            })
            .catch(error => console.error(`Error fetching pods info for ${facility}:`, error));
      });
    }
  };

  const startPolling = (service: Service) => {
    if (pollingInterval) {
      clearInterval(pollingInterval);
    }
    fetchPodsInfo(service);
    const interval = setInterval(() => fetchPodsInfo(service), 1000);
    setPollingInterval(interval);
  };

  const confirmFCRestart = (facility: string, podName: string) => {
    openModal(
        `Are you sure you want to restart ${facility}?`,
        <p>Pod Name: {podName}</p>,
        'Restart',
        () => handleRestart(facility, podName)
    );
  };

  const handleRestart = (facility: string, podName: string) => {
    xhr.post(`${config.API_BASE_URL}/api/k8s/restart`, { facility, namespace: 'fulfil-prod', podName })
        .then(response => {
          setSnackbarMessage(`Pod ${podName} in facility ${facility} has been restarted.`);
          setSnackbarOpen(true);
        })
        .catch(error => {
          console.error('Error restarting pod:', error);
          setSnackbarMessage(`Failed to restart pod ${podName} in facility ${facility}.`);
          setSnackbarOpen(true);
        });
  };

  const handleSnackbarClose = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const extractVersion = (image: string) => {
    return image.split(':')[1];
  };

  function extractAfterLastSlash(container: {image: string; name: string;}): string | null {
    const regex = /[^/]+$/;
    const match = container.image.match(regex);
    return match ? `${match[0]} (${container.name})` : null;
  }

  return (
      <div className="service-details">
        {!service ? (
            <LandingComponent />
        ) : (
            <>
              {podsInfo.length > 0 && (
                  <div className="pods-info">
                    <h2>{service.service_name} Pod Information</h2>
                    <table className="t1">
                      <thead>
                      <tr className="t1r">
                        <td className="t1rd t1rd1">Facility</td>
                        <td className="t1rd">Pod Name</td>
                        <td className="t1rd">Image</td>
                        <td className="t1rd">Age</td>
                        <td className="t1rd">Status</td>
                        <td className="t1rd t1rd2">Restart</td>
                      </tr>
                      </thead>
                      <tbody>
                      {podsInfo.map((info, index) => (
                          info.podsDetails.map((pod: Pod) => (
                              <tr key={`${index}-${pod.name}`}>
                                <td>{info.facility}</td>
                                <td>{pod.name}</td>
                                <td className="disFlex">
                                  {pod.containers.map(container => (
                                      <div className="image-chip" key={container.image}>
                                        <Chip variant="outlined" color="primary" label={extractAfterLastSlash(container)} />
                                      </div>
                                  ))}
                                </td>
                                <td>{pod.age}</td>
                                <td>{pod.status}</td>
                                <td>
                                  <Button
                                      variant="contained"
                                      onClick={() => confirmFCRestart(info.facility, pod.name)}
                                  >
                                    Restart
                                  </Button>
                                </td>
                              </tr>
                          ))
                      ))}
                      {!podsInfo.length &&
                          <tr>
                            <td>
                              <Skeleton animation="wave" />
                            </td>
                            <td>
                              <Skeleton animation="wave" />
                            </td>
                            <td>
                              <Skeleton animation="wave" />
                            </td>
                            <td>
                              <Skeleton animation="wave" />
                            </td>
                            <td>
                              <Skeleton animation="wave" />
                            </td>
                            <td>
                              <Skeleton animation="wave" />
                            </td>
                          </tr>
                      }
                      </tbody>
                    </table>
                  </div>
              )}
              <br />
              <h2>{service.service_name} Images</h2>
              <table>
                <thead>
                <tr>
                  <th>Build</th>
                  <th>Tags</th>
                  <th>Timestamp</th>
                  {!service?.is_continuous_deployment && <th>History</th>}
                  {!service?.is_continuous_deployment && <th>Actions</th>}
                  <th>Release Steps</th>
                </tr>
                </thead>
                <tbody>
                {images.map(image => (
                    <CommitRow
                        key={image.digest}
                        commit={{ ...image, service_id: serviceId }}
                        refreshCommits={() => fetchImages(serviceId, 1, true)}
                        isContinuousDeployment={service?.is_continuous_deployment}
                        deployedMap={deployedMap}
                        service={service}
                    />
                ))}
                {!images.length && rows.map((_, index) => (
                  <tr key={index}>
                    <td>
                    <Skeleton animation="wave" />
                    </td>
                    <td>
                    <Skeleton animation="wave" />
                    </td>
                    <td>
                    <Skeleton animation="wave" />
                    </td>
                    <td>
                    <Skeleton animation="wave" />
                    </td>
                    <td>
                    <Skeleton animation="wave" />
                    </td>
                    <td>
                    <Skeleton animation="wave" />
                    </td>
                  </tr>
                  ))
                }
                </tbody>
              </table>
              <br/>
              <Pagination disabled={!images.length} page={page} onChange={(_: ChangeEvent<unknown>, page: number) => fetchImages(serviceId, page, true)} count={10} variant="outlined" />
              <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
              >
                <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </>
        )}
      </div>
  );
};

export default ServiceDetails;
